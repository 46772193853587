import React, { Component } from 'react'

class CreditReportMn extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default CreditReportMn
